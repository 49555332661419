import { motion } from "framer-motion";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import emailjs from "emailjs-com";

import Swal from "sweetalert2";
import axios from "axios";

function Contact() {
  const form = useRef();
  const [toSend, setToSend] = useState({
    from_name: "",
    message: "",
    reply_to: "",
  });

  const [loading, setLoading] = useState(false);

  const verifyDisabled = () => {
    const { from_name, message, reply_to } = toSend;
    if (
      from_name.length > 1 &&
      reply_to.includes("@") &&
      reply_to.includes(".com") &&
      message.length > 10
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // axios({
    //   method: "POST",
    //   baseURL: "https://api.emailjs.com/api/v1.0/email/send",
    //   data: {
    // service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    // template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    // user_id: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    //     form.current
    //   },
    // })
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then((response) => {
        Swal.fire("Thanks!", "Your message was sent!", "success");
        console.log("SUCCESS!", response.status, response.text);
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong, please try again!",
        });
        console.log("FAILED...", err);
        setLoading(false);
      });
    setToSend({ from_name: "", message: "", reply_to: "" });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center flex-1 bg-transparent"
    >
      <div className="w-11/12 md:w-4/6 h-[580px] rounded-[5px] flex flex-col items-center bg-transparent md:gap-4">
        <div className="flex flex-col items-center w-full md:p-2 md:gap-0">
          <h1 className="font-titilium text-white text-[34px] md:text-[54px] tracking-wider">
            HOW CAN I HELP?
          </h1>
          <h1 className="text-portfolio-secondary-50 font-asap text-[14px] md:text-[15px] font-medium text-center">
            Do you have a question or are you interested in working with me?
          </h1>
          <h1 className="text-portfolio-secondary-50 font-asap text-[14px] md:text-[15px] font-medium text-center">
            Just fill out the forms below.
          </h1>
        </div>
        {/* <form
          className="flex flex-col w-full flex-1 p-6 gap-6"
          // action="mailto:matheusnugas@gmail.com"
          method="POST"
          enctype="multipart/form-data"
          onSubmit={onSubmit}
          ref={form}
        > */}
        <form
          ref={form}
          onSubmit={onSubmit}
          className="flex flex-col w-full flex-1 p-6 gap-6"
        >
          <div className="flex flex-col md:flex-row gap-6 md:gap-0 justify-between md:p-4">
            <input
              className="bg-white bg-opacity-10 border-b border-portfolio-secondary-50 w-full md:w-5/12 placeholder:text-portfolio-secondary-50 p-2 font-opensans text-white h-14 outline-none placeholder:font-extralight placeholder:text-[12px] disabled:cursor-not-allowed"
              placeholder="Name:"
              name="from_name"
              onChange={handleChange}
              value={toSend.from_name}
              required
              disabled={loading}
              autocomplete="off"
            />
            <input
              className="bg-white bg-opacity-10 border-b border-portfolio-secondary-50 w-full md:w-5/12 placeholder:text-portfolio-secondary-50 p-2 font-opensans text-white h-14 outline-none placeholder:font-extralight placeholder:text-[12px] disabled:cursor-not-allowed"
              placeholder="Your Email:"
              required
              name="reply_to"
              onChange={handleChange}
              value={toSend.reply_to}
              disabled={loading}
              autocomplete="off"
            />
          </div>
          <div className="md:p-4 w-full flex flex-1">
            <textarea
              className="bg-white bg-opacity-10 w-full resize-none font-opensans rounded-[5px] p-4 border-none active:ring-transparent text-white placeholder:text-portfolio-secondary-50 outline-none focus:outline-portfolio-secondary-50 placeholder:font-extralight placeholder:text-[12px] disabled:cursor-not-allowed"
              placeholder="Your message (minimum of 10 characters):"
              name="message"
              required
              onChange={handleChange}
              value={toSend.message}
              disabled={loading}
              autocomplete="off"
            />
          </div>
          <div className="w-full flex flex-col md:p-4">
            {/* <LargeIconBtn text="Submit" icon="mail" position="self-end" /> */}
            {!loading ? (
              <button
                type="submit"
                // onClick={handleClick}
                disabled={verifyDisabled()}
                className={`w-full md:w-[132px] h-[47px] flex items-center justify-center gap-5 md:gap-1 rounded-[4px] duration-100 font-opensans font-bold text-[16px] bg-portfolio-secondary-50 hover:bg-portfolio-secondary-100 active:bg-portfolio-secondary-15 self-center md:self-end disabled:bg-opacity-40 disabled:cursor-not-allowed disabled:hover:bg-portfolio-secondary-50 disabled:hover:bg-opacity-40`}
                // onClick={handleLearnMoreButton}
              >
                Submit
                <EnvelopeIcon className="w-6 h-6" />
              </button>
            ) : (
              <button
                disabled
                type="button"
                className="w-full md:w-[132px] h-[47px] flex items-center justify-center gap-5 md:gap-1 rounded-[4px] duration-100 font-opensans font-bold text-[16px] bg-portfolio-secondary-50 hover:bg-portfolio-secondary-100 active:bg-portfolio-secondary-15 self-center md:self-end disabled:bg-opacity-40 disabled:cursor-not-allowed disabled:hover:bg-portfolio-secondary-50 disabled:hover:bg-opacity-40"
              >
                <svg
                  aria-hidden="true"
                  role="status"
                  class="inline w-4 h-4 md:w-5 md:h-5 mr-1 md:mr-2 text-white animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Submitting
              </button>
            )}
          </div>
        </form>
      </div>
    </motion.div>
  );
}

export default Contact;
