import linkedinIcon from "../../assets/images/linkedin.png";
import githubIcon from "../../assets/images/github.png";
import { useNavigate } from "react-router-dom";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

function HomeHero() {
  const navigate = useNavigate();

  const handleLearnMoreButton = () => {
    navigate("/about");
  };

  return (
    <div className="flex flex-col gap-4 flex-1 justify-center items-center w-full md:w-3/4 md:p-12 ">
      <div className="flex flex-col items-center">
        <p className="text-white font-asap text-[16px] md:text-[18px] tracking-[8px] md:tracking-[18px] font-extralight">
          Matheus Nugas
        </p>
        <h1 className="font-titilium text-[32px] md:text-[72px] text-white text-center">
          FULLSTACK DEVELOPER
        </h1>
      </div>
      <hr className="w-5/6  border-portfolio-secondary-50" />
      <div className="gap-16 flex flex-col items-center justify-center w-full">
        <div className="flex gap-12">
          <a
            href="https://www.linkedin.com/in/matheusluznugas/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="linkedin"
              className="w-10 h-10 cursor-pointer duration-150 hover:scale-110"
              src={linkedinIcon}
            />
          </a>

          <a
            href="https://github.com/Matheusnugas"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="linkedin"
              className="w-10 h-10 cursor-pointer duration-150 hover:scale-110"
              src={githubIcon}
            />
          </a>
        </div>

        <div className="flex justify-center md:justify-end w-5/6">
          <button
            className={`w-[300px] md:w-[132px] h-[47px] flex items-center justify-center gap-5 md:justify-center md:gap-1 rounded-[4px] duration-100 font-opensans font-bold text-[16px] bg-portfolio-secondary-50 hover:bg-portfolio-secondary-100 active:bg-portfolio-secondary-150`}
            onClick={handleLearnMoreButton}
          >
            About me
            <ArrowRightCircleIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
