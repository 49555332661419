import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  IconButton,
} from "@material-tailwind/react";

import mlnLogo from "../../assets/images/mlnLogo.png";
import { Link, useLocation } from "react-router-dom";

export default function Example() {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();
  const { pathname } = location;

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navigation = [
    {
      href: "/",
      name: "Home",
    },
    {
      href: "/projects",
      name: "Projects",
    },
    {
      href: "/contact",
      name: "Contact",
    },
    {
      href: "/about",
      name: "About",
    },
  ];

  const generateNavList = () => {
    return navigation.map((item) => (
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className={`p-1 font-normal text-portfolio-secondary-50 ${
          pathname === item.href
            ? "underline underline-offset-8 decoration-4 decoration-white"
            : "hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-portfolio-secondary-50 before:absolute before:left-0 before:bottom-0 "
        }`}
      >
        <Link
          to={item.href}
          className="flex items-center font-opensans font-bold text-[16px] hover:text-portfolio-secondary-100 active:text-portfolio-secondary-150 duration-150"
        >
          {item.name}
        </Link>
      </Typography>
    ));
  };

  return (
    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full shadow-none bg-transparent rounded-none py-2 px-4 lg:px-8 lg:py-4 border-none">
        <div className="flex items-center justify-between text-blue-gray-900">
          <Link
            // as="a"
            to="/"
            className="mr-4 cursor-pointer py-1.5 text-port text-[30px] font-medium text-portfolio-secondary-50 font-titilium"
          >
            <img src={mlnLogo} alt="logo" className="w-24 h-8" />
          </Link>
          <div className="flex items-center gap-4">
            <div className="mr-4 hidden lg:block">
              <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
                {generateNavList()}
              </ul>
            </div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden text-portfolio-secondary-50"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>
          <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
            {generateNavList()}
          </ul>
        </MobileNav>
      </Navbar>
    </>
  );
}
