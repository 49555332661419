import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import NavBar from "./Components/organisms/NavBar";
import AnimatedRoutes from "./Components/Animations/AnimatedRoutes";
import Footer from "./Components/organisms/Footer";

function App() {
  return (
    <div className="bg-gradient-to-b from-portfolio-primary to-black flex flex-col h-full min-h-screen selection:bg-portfolio-secondary-50">
      <Router className="flex flex-col flex-1">
        <NavBar />
        <AnimatedRoutes />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
