import React from "react";
import { Home, About, Contact, Projects } from "../Pages";
// import About from "../Pages/About";
// import Contact from "../Pages/Contact";
// import Projects from "../Pages/Projects";
// import Test from "../Pages/Test";

const routes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/projects", element: <Projects /> },
  // { path: "/test", element: <Test /> },
];

export default routes;
