import { motion } from "framer-motion";
import aboutMe from "../../assets/images/aboutMe.png";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();

  const handleContactMeBtn = () => {
    navigate("/contact");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center flex-1 bg-transparent"
    >
      <div className="flex flex-col md:flex-row justify-around w-11/12 md:w-10/12">
        <div className="w-full md:w-2/5 flex flex-col items-center justify-center gap-6 p-4">
          <div className="flex flex-col gap-2">
            <h1 className="text-portfolio-secondary-50 font-titilium text-[32px] text-center md:text-[54px]">
              SO, WHO AM I?
            </h1>
            <p className="text-white font-asap text-[16px] font-light text-start">
              Welcome to my portfolio! My name is{" "}
              <b className="text-portfolio-secondary-50 font-bold">
                Matheus Nugas
              </b>
              , and I am a full-stack developer with a passion for building web
              applications that are both functional and aesthetically pleasing.
              My expertise lies in NodeJS and React, and I also have experience
              with Firebase/Firestore, NestJS, NextJS, Tailwind, and more. I
              love working with cutting-edge technologies and learning new
              things to improve my skills. My journey as a developer began with
              my fascination for how technology has the power to transform the
              world we live in. I've always been drawn to the idea of creating
              digital products that have a positive impact on people's lives.
              Over the years, I've honed my skills, working on a variety of
              projects that have challenged me to think creatively and
              critically. As a developer, I believe in the importance of
              collaboration and teamwork. I enjoy working with others to bring a
              project to life and strive to create a supportive and inclusive
              work environment. I also value open communication and
              transparency, which I believe are crucial to the success of any
              project.
            </p>
          </div>
          <button
            className={`w-[300px] my-4 md:my-0 md:w-[132px] h-[47px] flex items-center justify-center md:self-end gap-5 md:justify-center md:gap-1 rounded-[4px] duration-100 font-opensans font-bold text-[16px] bg-portfolio-secondary-50 hover:bg-portfolio-secondary-100 active:bg-portfolio-secondary-150`}
            onClick={handleContactMeBtn}
          >
            Contact me
            <ArrowRightCircleIcon className="w-6 h-6" />
          </button>
          {/* <LargeIconBtn text="Contact me" position="self-end" /> */}
        </div>
        <div className="hidden md:flex w-2/5 items-center justify-center">
          <img src={aboutMe} alt="me" className="w-80 rounded-[5px]" />
        </div>
      </div>
    </motion.div>
  );
}

export default About;
