import routes from "../../routes";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes(props) {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {routes.map((route) => (
          <Route exact path={route.path} element={route.element} />
        ))}
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
