import { motion } from "framer-motion";
import underConstruction from "../../assets/images/underConstruction.png";
import { Progress } from "@material-tailwind/react";

function Projects() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center flex-1 bg-transparent"
    >
      <div className="bg-transparent w-11/12 md:w-2/3 items-center justify-center flex flex-col gap-12">
        <h1 className="font-titilium text-white text-[32px] md:text-[56px] text-center">
          THIS SECTION IS{" "}
          <span className="text-portfolio-secondary-50">ALMOST</span> DONE...
        </h1>
        <img
          src={underConstruction}
          className="h-1/2 md:h-1/3 w-1/2 md:w-1/3"
          alt="under construction"
        />
        <Progress value={60} label="Completed" className="w-2/3" />
      </div>
    </motion.div>
  );
}

export default Projects;
