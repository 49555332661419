import HomeHero from "../../Components/organisms/HomeHero";
import { motion } from "framer-motion";

function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col items-center justify-center flex-1 bg-transparent"
    >
      <HomeHero />
    </motion.div>
  );
}

export default Home;
